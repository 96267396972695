export default [
    {
        type: 'selection',
        width: 38,
        align: 'center'
    },

    {
        title: 'Emp Code',
        key: 'EmpCode',
        sortable: true,
        width: 100,
        minWidth: 80,
    },

	{
        title: 'Name',
        key: 'Name',
		sortable: true,
        minWidth: 80,
    },

    {
        title: 'Company',
        key: 'Company',
        width: 100,
        minWidth: 80,
		sortable: true,
    },

    {
        title: 'Designation',
        key: 'Designation',
        width: 300,
        minWidth: 80,
    },

    {
        title: 'Mobile',
        key: 'MobileNo',
        width: 125,
        minWidth: 80,
    },

    {
        title: 'Manager',
        key: 'Manager',
        width: 200,
        minWidth: 80,
    },
    
    {
        title: 'Joining Date',
        key: 'DateOfJoining',
        width: 125,
        minWidth: 80,
		sortable: true,
    },
]
